<template>
  <el-container class="shopList ">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>企业管理</el-breadcrumb-item>
        <el-breadcrumb-item>企业列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
      <el-table :data="companyData" border fit>
        <el-table-column align="center" width="60" type="index" label="序号"></el-table-column>
        <el-table-column property="company_name" label="企业名称" align="center" min-width="120"
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column property="credit_code" label="统一社会信用代码" align="center" min-width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="经营地址" align="center" min-width="150" :show-overflow-tooltip="true">
          <template
            slot-scope="scope">{{scope.row.province}}{{scope.row.city}}{{scope.row.area}}{{scope.row.town}}{{scope.row.vill}}{{scope.row.address}}</template>
        </el-table-column>

        <el-table-column property="" label="业务类型" align="center" min-width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope">
              <span v-if="scope.row.business_type==1">首次</span>
              <span v-else-if="scope.row.business_type==2" style="color:#9D9D9D">延续</span>
              <span v-else-if="scope.row.business_type==3" class="red">注销</span>
              <span v-else style="color: #FAA500">变更</span>
        </template>
        </el-table-column>
        <el-table-column property="filing_state" label="审核状态" align="center" min-width="120" :show-overflow-tooltip="true">
           <template slot-scope="scope">
              <el-tag v-if="scope.row.filing_state==1" type="warning">待完善</el-tag>
              <el-tag v-else-if="scope.row.filing_state==2" type="danger">待审核</el-tag>
              <el-tag v-else-if="scope.row.filing_state==3" >已归档</el-tag>
              <el-tag v-else-if="scope.row.filing_state==4" type="info">已驳回</el-tag>
        </template>
        </el-table-column>
        <el-table-column label="操作" class-name='aa' fixed="right" min-width="150" align='center'>
          <template slot-scope="scope">
            <el-button type="text" class="orange disib" size="mini" @click="onUpdate(scope.row.company_id)">编辑主体
            </el-button>
            <el-button type="text" v-if="scope.row.business_type!=3" class="red disib" size="mini" @click="onDelete(scope.row)">注销
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page fr disib mt20">
        <el-pagination background :page-size='10' layout="total,prev, pager, next" :total="total"
          :current-page="currentPage" @current-change='currentChange'>
        </el-pagination>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local, aa,session } from '../../../util/util'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      user: '',
      companyData: [],
      currentPage: 1,
      total: 0,
      page: {
        pager_offset: '0',
        pager_openset: '10'
      },
      form:{
        business_type:'',
        company_id:'',
        company_tag_id:'',
        company_name:'',
        company_logo_url:'',
        business_state:'',
        filing_state:'',
        province:"",
        city:"",
        area:"",
        town:"",
        vill:"",
      }
    }
  },
  created () {
    var that = this;
    that.user = local.get('user');
    that.currentPage = that.page.pager_offset / 10 + 1;
    this.getCompany(that.page)
  },
  mounted () {
  },
  methods: {
    getCompany (params) {
      axios.get('/pc/company/list', params).then((v) => {
        this.companyData = v.data.company_list;
        this.total = v.data.pager_count;
      })
    },
    onUpdate (id) {
      session.set('params',{id:id,name:''})
      this.$router.push({
        name: "CompanyUpdate",
      });
    },
    // 分页
    currentChange (page) {
      var that = this;
      that.currentPage = page;
      that.page.pager_offset = String((page - 1) * 10)
      that.getCompany(that.page)
    },
    onDelete(row){
        const h = this.$createElement;
        this.$msgbox({
          title: '提示',
          message: h('p', null, [
            h('p', { style: 'font-size: 14px;color:#333' }, row.company_name),
            h('p', { style: 'font-size: 14px;color:#333' }, row.credit_code),
            h('p', { style: 'font-size: 14px;color:#E02626' }, '一旦注销，数据无法恢复，请谨慎操作！')
          ]),
          center: true,
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.changeCompany(row)
        })
    },
    changeCompany(row){
      this.form.business_type='3';
      this.form.company_id=row.company_id;
      this.form.company_tag_id=row.company_tag_id;
      this.form.company_name=row.company_name;
      this.form.company_logo_url=row.company_logo_url;
      this.form.business_state=row.business_state;
      this.form.filing_state=row.filing_state;
      this.form.province=row.province;
      this.form.city=row.city;
      this.form.area=row.area;
      this.form.town=row.town;
      this.form.vill=row.vill;
      this.form.credit_code=row.credit_code
       axios.put('/pc/company/update', this.form).then((v) => {
           row.business_type='3';
            })
    },
  }
}
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
